import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, tap, catchError, mergeMap } from 'rxjs/operators';
import { JobFilters } from '../models/filters.model';
import { Job, JobLocation, JobTimeExtent } from '../models/job.model';
import { AppConfigService } from './app-config.service';
import { SavedJobsService } from './saved-jobs.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ErrorHandlingService } from './error-handling.service';
import { Pagination } from '../models/pagination.interface';
import { JobCategory } from '../models/job-category.model';
import { JobApplication } from '../models/job-application.model';
import { convertStringDateToJSDate, randomIntFromInterval, readQueryParameters } from '../shared-functions';
import { ICompany } from '../models/company.model';
import { JOBS_PAGES } from '../models/jobs-pages';

export const EMPTY_FILTERS = { location: null, typeOfEmployment: null, category: null, searchBy: null };

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  private _searchBy: string = null;
  private _category: string = null;
  private _location: string = null;
  private _country: string = null;
  private _typeOfEmployment: string = null;
  private _timeExtent: string = null;
  private _filtersUpdated$: BehaviorSubject<JobFilters> = new BehaviorSubject<JobFilters>(EMPTY_FILTERS);
  private _typesOfEmploymentList: JobTimeExtent[];
  private _categoriesList: JobCategory[];
  private _locations: Map<string, JobLocation[]> = new Map<string, JobLocation[]>();

  constructor(
    private http: HttpClient,
    private configService: AppConfigService,
    private savedJobsService: SavedJobsService,
    private router: Router,
    private errorHandlingService: ErrorHandlingService,
    private translateService: TranslateService
  ) {
    const filters = this.getFilters();
    if (filters) {
      this._filtersUpdated$.next(filters);
    }
  }

  get filtersUpdated$(): Observable<JobFilters> {
    return this._filtersUpdated$.asObservable();
  }

  get category(): string {
    return this._category;
  }

  set category(id: string) {
    this._category = id;
    this.saveFiltersToLocalStorage('category', this._category);
    this._filtersUpdated$.next(this.getFilters());
  }

  get location(): string {
    return this._location;
  }

  set location(id: string) {
    this._location = id;
    this.saveFiltersToLocalStorage('location', this._location);
    this._filtersUpdated$.next(this.getFilters());
  }

  get country(): string {
    return this._country;
  }

  set country(id: string) {
    this._country = id;
    this.saveFiltersToLocalStorage('country', this._country);
  }

  get searchBy(): string {
    return this._searchBy;
  }

  set searchBy(term: string) {
    this._searchBy = term;
    this.saveFiltersToLocalStorage('searchBy', this._searchBy);
    this._filtersUpdated$.next(this.getFilters());
  }

  get typeOfEmployment(): string {
    return this._typeOfEmployment;
  }

  set typeOfEmployment(id: string) {
    this._typeOfEmployment = id;
    this.saveFiltersToLocalStorage('typeOfEmployment', this._typeOfEmployment);
    this._filtersUpdated$.next(this.getFilters());
  }

  get timeExtent(): string {
    return this._timeExtent;
  }

  set timeExtent(id: string) {
    this._timeExtent = id;
    this.saveFiltersToLocalStorage('timeExtent', this._timeExtent);
    this._filtersUpdated$.next(this.getFilters());
  }

  get companyIds(): string {
    const { id, isEnterprise, isAllJobs, companies } = this.configService.organization;

    return  isEnterprise || isAllJobs
      ? companies.map(company => company.id).join()
      : id.toString();
  }

  private saveFiltersToLocalStorage(filter: string, value: string): void {
    const filters = JSON.parse(sessionStorage.getItem('filters'));
    if (filters) {
      sessionStorage.setItem('filters', JSON.stringify({
        ...filters,
        [filter]: value
      }));
    } else {
      sessionStorage.setItem('filters', JSON.stringify({
        [filter]: value
      }));
    }
  }

  updateFilters(filters: JobFilters): void {
    Object
      .keys(filters)
      .forEach((filterKey: string) => this.saveFiltersToLocalStorage(filterKey, filters[filterKey]));
    this._filtersUpdated$.next(filters);
  }

  getFilters(): JobFilters {
    const filters = JSON.parse(sessionStorage.getItem('filters'));

    if (filters) {
      Object
        .keys(filters)
        .forEach((filterKey: string) => {
          if (filters[filterKey] === 'null') {
            filters[filterKey] = null;
          }
        });
      return filters;
    } else {
      return { category: null, typeOfEmployment: null, location: null, country: null, searchBy: null };
    }
  }

  getCategories(): Observable<JobCategory[]> {
    if (this._categoriesList) {
      return of(this._categoriesList);
    }
    let params = new HttpParams();

    params = params.set('limit', '1000');
    if (this.companyIds) {
      params = params.set('companyIds', this.companyIds);
    }

    return this.http.get(`${environment.companyCategories}`, { params })
      .pipe(
        map(({data}: Pagination<JobCategory>) => data),
        tap((categories: JobCategory[]) => this._categoriesList = categories)
      );
  }

  getLocations(countryId?: string): Observable<JobLocation[]> {
    let params = new HttpParams();

    params = params.set('limit', '1000');
    if (this.companyIds) {
      params = params.set('companyIds', this.companyIds);
    }

    if (countryId) {
      const cachedLocations = this._locations.get(countryId);
      if (cachedLocations) {
        return of(cachedLocations);
      }
      params = params.set('country', countryId);
    }

    return this.http.get(`${environment.companyLocations}`, { params })
      .pipe(
        map(({data}: Pagination<JobLocation>) => data),
        tap((locations: JobLocation[]) => this._locations.set(countryId, locations)),
      );
  }

  getTypesOfEmployment(): Observable<JobTimeExtent[]> {
    if (this._typesOfEmploymentList) {
      return of(this._typesOfEmploymentList);
    }

    let params = new HttpParams();

    params = params.set('limit', '1000');
    if (this.companyIds) {
      params = params.set('companyIds', this.companyIds);
    }

    return this.http.get(`${environment.companyTypesOfEmployment}`, { params })
      .pipe(
        map(({data}: Pagination<JobTimeExtent>) => data),
        tap((typesOfEmployment: JobTimeExtent[]) => this._typesOfEmploymentList = typesOfEmployment)
      );
  }

  // Unused method, check if endpoint exists and talk with backend to remove it if it's unnecessary
  // getTimeExtents() {
  //   let params = new HttpParams();
  //   params = params.set('limit', '1000');
  //   return this.http.get(`${environment.companyTimeExtents}`, { params })
  //     .pipe(
  //       map(({data}: Pagination<JobTimeExtent>) => data)
  //     );
  // }

  getJobsForLandingPage(): Observable<Pagination<Job>> {
    const { guid, isEnterprise } = this.configService.organization;
    const language = this.translateService.currentLang;

    const endpoint = isEnterprise
      ? environment.enterpriseJobs
      : environment.companyJobs;

    let params = new HttpParams();

    params = params.set('publishOnHigher', '1');
    params = params.set('status', 'active');
    params = params.set('limit', '4');
    params = params.set('page', '1');

    return this.http
      .get<Pagination<Job>>(
        `${endpoint}/${guid}/jobs`,
        {
          headers: new HttpHeaders({ 'Accept-language': language }),
          params
        }
      )
      .pipe(
        tap(({data}: Pagination<Job>) => this.savedJobsService.mapSavedJobs(data))
      );
  }

  getJobs(filters: JobFilters): Observable<Pagination<Job>> {
    const { guid, isEnterprise, isAllJobs } = this.configService.organization;
    const language = this.translateService.currentLang;

    let params = new HttpParams();

    if (filters.category) {
      params = params.set('category', filters.category);
    }

    if (filters.location) {
      params = params.set(isEnterprise || isAllJobs ? 'locations' : 'location', filters.location);
    }

    if (filters.typeOfEmployment) {
      params = params.set('typeOfEmployment', filters.typeOfEmployment);
    }

    if (filters.searchBy) {
      params = params.set('searchBy', filters.searchBy);
    }

    if (filters.page) {
      params = params.set('page', filters.page);
    }

    if (filters.country) {
      params = params.set('country', filters.country);
    }

    params = params.set('publishOnHigher', '1');
    params = params.set('status', 'active');
    params = params.set('limit', '6');

    let endpoint = '';
    if (isAllJobs) {
      endpoint = environment.jobs;
      const queries = JOBS_PAGES.get(this.configService.organization.name).queries;
      queries.forEach(query => {
        params = params.set(query, 1);
      });
    } else {
      const endpointString = isEnterprise
        ? environment.enterpriseJobs
        : environment.companyJobs;
        endpoint = `${endpointString}/${guid}/jobs`;
    }

    return this.http
      .get(endpoint, {
        headers: new HttpHeaders({ 'Accept-language': language }),
        params
      })
      .pipe(
        tap(({data}: Pagination<Job>) => this.savedJobsService.mapSavedJobs(data))
      );
  }

  getJob(): Observable<Job> {
    const jobGUID = readQueryParameters('job');
    const headers = new HttpHeaders({ 'Accept-language': this.translateService.currentLang });

    return this.http.get(`${environment.job}/${jobGUID}`, { headers })
      .pipe(
        map(({data}: Pagination<Job>) => {
          const job = data[0];
          job.deadlineDate = convertStringDateToJSDate(job.applicationDate);

          return job;
        }),
        catchError((errorResponse: HttpErrorResponse) => {
          this.router.navigate(['/jobs']);
          return this.errorHandlingService.handleBackendError(errorResponse);
        })
      );
  }

  sendSmsLink(guid: string, language: string): Observable<JobApplication> {
    const headers = new HttpHeaders({
      'Accept-Language': language
    });

    return this.http.put(`${environment.applications}/${guid}/send_application_link_sms`, null, { headers })
      .pipe(
        map(({data}: Pagination<JobApplication>) => data[0]),
      );
  }

  addQueryParamsToPreventChashing(): void {
    this.router.navigate([], {
      queryParams: {
        v: randomIntFromInterval(1, 100),
      }
    });
  }

  getAdditionalJobsToApplyTo(boundedJobIds: number[]): Observable<Job[]> {
    const requests$ = boundedJobIds
      .map((id: number) => {
        return this.http.get(`${environment.jobs}/${id}`)
          .pipe(
            map(({data}: Pagination<Job>) => data[0])
          );
      });

    return forkJoin(requests$);
  }

  getCompanyByDomain(domain: string): Observable<ICompany> {
    return this.http.get(`${environment.companyInfo}/${domain}`)
      .pipe(
        map(({data}: Pagination<ICompany>) => data[0] || null)
      );
  }
}
